@use "src/styles/variables";

.scores-wrapper {
    display: grid;
    grid-template-columns: minmax(1fr, auto);
    grid-row-gap: $spacing-16;
    margin: 0 auto;

    @media only screen and (min-width: calc($screen-768)) {
        grid-template-columns: repeat(2, 1fr);
        grid-column-gap: $spacing-16;
        grid-row-gap: $spacing-20;
    }
}

.score-card {
    width: $width-320;
    max-width: $width-320;
    box-shadow: $box-shadow-score-card;
    border-radius: 0.25rem;
    display: flex;
    gap: $spacing-12;
}

.score-title {
    &.mat-mdc-card-title {
        display: inline-flex;
        align-items: baseline;
        gap: 0.625rem;
        text-align: flex-start;
        width: $w_100;
        font-weight: $font-weight-500;
        font-size: $font-size-16;
        line-height: 150%;
        letter-spacing: $letter-spacing-0_15;
    }

    .mat-icon {
        font-size: $font-size-18;
        color: $on-surface;
        cursor: pointer;
    }
}

.help-tooltip {
    box-shadow: $box-shadow-score-card;
    border-radius: 0.25rem;

    .mat-mdc-menu-content {
        padding: $spacing-12;
        font-size: $font-size-12;
        line-height: 133%;
        letter-spacing: $letter-spacing-0_4;
        color: $on-background;
    }

    &-content {
        display: flex;
        flex-direction: column;
        gap: 0.25rem;

        .header {
            font-weight: $font-weight-500;
            font-size: $font-size-16;
            line-height: 150%;
            letter-spacing: $letter-spacing-0_15;
        }
    }
}

.score-subtitle {
    &.mat-mdc-card-subtitle {
        margin-top: $spacing-4;
        color: $on-background;
        font-weight: $font-weight-400;
        font-size: $font-size-14;
        line-height: 143%;
        letter-spacing: $letter-spacing-0_25;
        text-align: left;
    }
}

.score-content {
    &.mat-mdc-card-content {
        display: grid;
        grid-template-columns: 30% 65%;
        grid-column-gap: $spacing-14;
        align-items: center;

        .patient-score {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: baseline;
            padding: $spacing-12;
            background-color: rgba($on-surface, 0.12);
            box-shadow: $box-shadow-patient-score;
            border-radius: 0.25rem;

            .current-score {
                font-weight: $font-weight-700;
                font-size: $font-size-22;
                line-height: 127%;
            }

            .total-score {
                font-size: $font-size-14;
                line-height: 143%;
                letter-spacing: $letter-spacing-0_1;
                color: $on-surface;
            }
        }
    }
}

.score-comparison {
    gap: 0.5rem;
    display: flex;
    flex-direction: column;

    &-element {
        display: flex;
        flex-direction: row;
        gap: 0.5rem;
        justify-content: flex-start;
        align-items: baseline;
        
        &-value {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: baseline;
            font-weight: $font-weight-800;
            font-size: $font-size-14;
            line-height: 143%;
            letter-spacing: $letter-spacing-0_25;

            &.deminish {
                color: $error;
                .mat-icon {
                    font-size: $font-size-15;
                    color: $error;
                    height: min-content;
                }
            }

            &.increase {
                color: $green;
                .mat-icon {
                    font-size: $font-size-15;
                    color: $green;
                    height: min-content;
                }
            }
        }

        &-label {
            font-weight: $font-weight-400;
            font-size: $font-size-14;
            line-height: 143%;
            letter-spacing: $letter-spacing-0_25;
            color: $on-surface;
        }
    }
}
@use "src/styles/variables";

.btn {
  &.mdc-button {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    padding: $spacing-10 $spacing-24;
    cursor: pointer;
    height: max-content;
    border-radius: 6.25rem;
    background: $surface;
    line-height: 143%;
    letter-spacing: $letter-spacing-0_1;
    min-width: 7.5rem;

    @media only screen and (max-width: calc($screen-768 - 1px)) and (orientation: portrait) {
      width: $w_100;
    }

    .mat-mdc-button-touch-target {
      height: fit-content;
    }

    .mdc-button__label{
      display: flex;
      align-items: center;
      text-align: center;
      justify-content: flex-start;
      gap: 0.8rem;

      .ng-star-inserted {
        display: flex;
        align-items: center;
      }

      .mat-icon {
        width: min-content;
        height: min-content;
        font-size: $font-size-16;
      }

      .white_icon {
        color: $white;
      }
    }

    &.filled-primary {
      border: 1px solid var(--theme-primary-500);
      background: var(--theme-primary-500);
      color: $white;

      .mat-icon {
        color: $white;
      }

      &:hover {
        border: 1px solid $surface;
        background: $surface;
        box-shadow: $box-shadow;
        color: var(--theme-primary-500);

        .mat-icon {
          color: var(--theme-primary-500);
        }
      }

      &:focus {
        background: $background;
        background: var(--theme-primary-500);
        border: 3px solid var(--theme-primary-400);
        color: $white;

        .mat-icon {
          color: $white;
        }
      }

      &:active {
        background: var(--theme-primary-700);
        font-weight: $font-weight-900;
        color: $white;
        border: none;

        .mat-icon {
          color: $white;
        }
      }
    }

    &.filled-secondary {
      background: var(--theme-secondary-500);
      color: $white;

      .mat-icon {
        color: $white;
      }

      &:hover {
        background: $surface;
        box-shadow: $box-shadow;
        color: var(--theme-secondary-500);

        .mat-icon {
          color: var(--theme-secondary-500);
        }
      }

      &:focus {
        background: var(--theme-secondary-500);
        border: 3px solid var(--theme-secondary-400);
        color: $white;

        .mat-icon {
          color: $white;
        }
      }

      &:active {
        background: var(--theme-secondary-700);
        font-weight: $font-weight-900;
        color: $white;
        border: none;

        .mat-icon {
          color: $white;
        }
      }
    }

    &.outlined-primary {
      background: $background;
      color: var(--theme-primary-500);
      border: 1px solid $on-surface;

      &.disabled {
        color: $disabled;
        border: 1px solid $disabled;

        .mat-icon {
          color: $disabled;
        }
      }

      .mat-icon {
        color: var(--theme-primary-500);
      }

      &:hover {
        background: $surface;
        box-shadow: $box-shadow;
        color: var(--theme-primary-500);

        .mat-icon {
          color: var(--theme-primary-500);
        }
      }

      &:focus {
        background: $background;
        border: 3px solid $on-surface;
        color: var(--theme-primary-500);

        .mat-icon {
          color: var(--theme-primary-500);
        }
      }

      &:active {
        background: $surface;
        box-shadow: $box-shadow;
        color: var(--theme-primary-500);
        font-weight: $font-weight-900;

        .mat-icon {
          color: var(--theme-primary-500);
        }
      }
    }

    &.filled-secondary {
      background: var(--theme-secondary-500);
      color: $white;

      .mat-icon {
        color: $white;
      }

      &:hover {
        background: $surface;
        box-shadow: $box-shadow;
        color: var(--theme-secondary-500);

        .mat-icon {
          color: var(--theme-secondary-500);
        }
      }

      &:focus {
        background: $background;
        background: var(--theme-secondary-500);
        border: 3px solid var(--theme-secondary-400);
        color: $white;

        .mat-icon {
          color: $white;
        }
      }

      &:active {
        background: var(--theme-secondary-700);
        font-weight: $font-weight-900;
        color: $white;
        border: none;

        .mat-icon {
          color: $white;
        }
      }
    }
  }
}

.mat-icon {
  width: min-content;
  height: min-content;
  font-size: $font-size-16;
}

.label_before {
  .mdc-button__label {
    flex-direction: row;
  }

  &.mdc-button {
    padding-left: $spacing-16;
  }
}

.label_after {
  .mdc-button__label {
    flex-direction: row-reverse;
  }

  &.mdc-button {
    padding-right: $spacing-16;
  }
}


@use "src/styles/variables";

app-video-player {
    .youtube-player {
        display: flex;
        flex-direction: column;
        gap: $spacing-16;

        @media only screen and (min-width: calc($screen-768)) {
            gap: $spacing-20;
        }

        .alt-text {
            text-align: left;
            font-size: $font-size-14;
            line-height: 143%;
            letter-spacing: $letter-spacing-0_25;

            @media only screen and (min-width: $screen-1140) {
                font-size: $font-size-16;
                line-height: 150%;
                letter-spacing: $letter-spacing-0_5;
            }
        }        
    }

    .youtube-player.mt-24 {
        margin-top: $spacing-20;

        @media only screen and (min-width: calc($screen-768)) {
            margin-top: $spacing-24;
        }
    }
}
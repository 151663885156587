@use "src/styles/variables";


::-webkit-scrollbar {
  width: 1.2rem;
  background: $back-background;
}

::-webkit-scrollbar-thumb {
  border: $scrollbar-thumb-border;
  background: $color-black-45-percent;
  border-radius: 0.75rem;
  width: 0.5rem;
  background-clip: padding-box;
}

.container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: $w_100;
  margin: 0 auto;
  background: $background;
  max-width: $screen-768;

  /* Large devices (laptops/desktops, 1140 and up) */
  @media only screen and (min-width: $screen-1140) {
    max-width: $screen-1140;
  }
}

main {
  flex: 1;
}

.wrapper-shadow {
  position: relative;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  overflow-y: scroll;
}

.shadow {
  position: fixed;
  left: 0;
  z-index: 100;
  width: 100%;
  height: 1.125rem;

  &--bottom {
    bottom: 0;
    background: linear-gradient(90deg, rgb(0 0 0 / 60%), transparent);
    background: linear-gradient(0deg, rgb(0 0 0 / 60%), transparent);
  }
}

.wrapper {
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
  flex-direction: column;
  align-items: center;
  flex: 1;
  max-width: $screen-768;

  @media only screen and (max-width: calc($screen-768 - 1px)) and (orientation: portrait) {
    max-width: $screen-360;
  }

  &.gap {
    gap: $spacing-56;

    @media only screen and (max-width: calc($screen-768 - 1px)) and (orientation: portrait) {
      gap: $spacing-40;
    }
  }
}

.text-content {
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
  text-align: center;
  font-size: $font-size-28;
  line-height: 129%;
  max-width: $width-455;
  margin: $spacing-48 auto 0 auto;

  @media only screen and (max-height: calc($screen-768 - 1px)) and (orientation: landscape){
    font-size: $font-size-24;
    line-height: 133%;
  }

  @media only screen and (max-width: calc($screen-768 - 1px)) and (orientation: portrait){
    margin-top: $spacing-36;
    max-width: $width-320;
    font-size: $font-size-24;
    line-height: 133%;
    gap: 0.5rem;
    text-align: left;
  }
}

.content {
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
  margin: $spacing-24 auto 0 auto;
  text-align: center;
  width: $width-420;
  max-width: $width-420;
  font-size: $font-size-22;
  line-height: 127%;

  @media only screen and (max-width: calc($screen-768 - 1px)) and (orientation: landscape) {
    font-size: $font-size-16;
    line-height: 150%;
    letter-spacing: $letter-spacing-0_15;
  }

  @media only screen and (max-width: calc($screen-768 - 1px)) and (orientation: portrait) {
    width: $width-320;
    max-width: $width-320;
    font-size: $font-size-16;
    line-height: 150%;
    letter-spacing: $letter-spacing-0_15;
  }
}

.content-eq5d {
  @media only screen and (min-width: calc($screen-768)) {
    display: grid;
    grid-template-columns: 75% 25%;
    grid-column-gap: 2rem;
    margin: $spacing-24 auto 0 auto;
    text-align: center;
    max-width: $width-572;
    font-size: $font-size-20;
    line-height: 140%;
  }

  @media only screen and (max-width: calc($screen-768 - 1px)) {
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
    margin: $spacing-24 auto 0 auto;
    text-align: center;
  }

  @media only screen and (max-width: calc($screen-768 - 1px)) and (orientation: landscape) {
    font-size: $font-size-14;
    line-height: 171%;
    letter-spacing: $letter-spacing-0_15;
  }

  @media only screen and (max-width: calc($screen-768 - 1px)) and (orientation: portrait) {
    width: $width-320;
    max-width: $width-320;
    font-size: $font-size-14;
    line-height: 171%;
    letter-spacing: $letter-spacing-0_15;
  }
}

.splash {
  gap: 0;
  flex-wrap: wrap;
  padding: $spacing-24 $spacing-40 0 $spacing-40;
  flex-direction: column;
  align-items: center;
  flex: 1;
  max-width: $screen-768;
  font-size: $font-size-22;
  line-height: 136%;

  @media only screen and (max-width: calc($screen-768 - 1px)) and (orientation: landscape) {
    font-size: $font-size-16;
    line-height: 150%;
    letter-spacing: $letter-spacing-0_15;
  }

  @media only screen and (max-width: calc($screen-768 - 1px)) and (orientation: portrait) {
    max-width: $width-320;
    padding: $spacing-24 $spacing-20 0 $spacing-20;
    font-size: $font-size-16;
    line-height: 150%;
    letter-spacing: $letter-spacing-0_15;
  }
}

app-question,
app-splash {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - $header-devices-height - $footer-img-height - 2*$spacing-12);

  @media only screen and (min-width: variables.$screen-1140) {
    min-height: calc(100vh - $header-desktop-height - $footer-img-height - 2*$spacing-12 - 0.25rem);
  }

  .battery-copyright {
    display: flex;
    flex-direction: column;
    text-align: center;
    padding: $spacing-12 $spacing-20;

    @media only screen and (min-width: variables.$screen-768) {
      padding-left: $spacing-111_5;
      padding-right: $spacing-111_5;
    }

    @media only screen and (min-width: variables.$screen-1140) {
      padding-top: $spacing-16;
      padding-bottom: $spacing-16;
    }

    color: $on-surface;
    font-size: $font-size-12;
    line-height: $line-height-16;
    letter-spacing: $letter-spacing-0_5;
  }

  footer {
    flex-wrap: wrap;
    justify-content: space-evenly;
    background: $sub-surface;
    padding: $spacing-16 $spacing-20;
    gap: 1.25rem;

    @media only screen and (min-width: variables.$screen-1140) {
      font-size: $font-size-14;
      line-height: $line-height-20;
      letter-spacing: $letter-spacing-0_1;
    }

    @media only screen and (min-width: $screen-768) and (min-height: $screen-768) {
      padding: $spacing-24 $spacing-32;
    }

    ul {
      list-style: none;
      padding-left: 0;
      margin: 0;
      color: $on-surface;
      font-weight: $font-weight-500;
      font-size: $font-size-12;
      line-height: $line-height-16;

      @media only screen and (min-width: variables.$screen-1140) {
        font-size: $font-size-14;
        line-height: $line-height-20;
        letter-spacing: $letter-spacing-0_1;
      }

      li {
        width: max-content;
      }

      .active {
        font-weight: $font-weight-800;
        font-size: $font-size-12;
        line-height: $line-height-20;
        letter-spacing: $letter-spacing-0_1;

        @media only screen and (min-width: variables.$screen-1140) {
          font-size: $font-size-16;
        }
      }
    }
  }
}

.status-page,
app-status-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: calc(100vh - $header-devices-height - $footer-img-height - 2*$spacing-12);

  @media only screen and (min-width: variables.$screen-1140) {
    min-height: calc(100vh - $header-desktop-height - $footer-img-height - 2*$spacing-12 - 0.25rem);
  }

  .content-scores-page {
    display: flex;
    flex-direction: column;
    gap: $spacing-24;
    padding: $spacing-48 $spacing-40 $spacing-24 $spacing-40;
    text-align: center;
    font-size: $font-size-16;
    line-height: 150%;
    letter-spacing: $letter-spacing-0_5;

    @media only screen and (max-height: calc($screen-768 - 1px)) and (orientation: landscape){
      padding: $spacing-32 $spacing-40 $spacing-24 $spacing-40;
      font-size: $font-size-14;
      line-height: 143%;
      letter-spacing: $letter-spacing-0_25;
      gap: $spacing-12;
    }

    @media only screen and (max-width: calc($screen-768 - 1px)) and (orientation: portrait){
      gap: $spacing-12;
      padding: $spacing-32 $spacing-20 $spacing-24 $spacing-20;
      font-size: $font-size-14;
      line-height: 143%;
      letter-spacing: $letter-spacing-0_25;
    }
  }

  .scores-title {
      text-align: center;
      font-size: $font-size-24;
      line-height: 133%;

      @media only screen and (max-height: calc($screen-768 - 1px)) and (orientation: landscape){
        font-size: $font-size-22;
        line-height: 127%;
      }

      @media only screen and (max-width: calc($screen-768 - 1px)) and (orientation: portrait){
        font-size: $font-size-22;
        line-height: 127%;
      }
  }

  .content-status-page {
    display: flex;
    flex-direction: column;
    padding: $spacing-48 $spacing-40 0 $spacing-40;
    text-align: center;
    font-size: $font-size-28;
    line-height: 129%;

    @media only screen and (max-height: calc($screen-768 - 1px)) and (orientation: landscape){
      padding: $spacing-32 $spacing-40 0 $spacing-40;
      font-size: $font-size-24;
      line-height: 133%;
    }

    @media only screen and (max-width: calc($screen-768 - 1px)) and (orientation: portrait){
      padding: $spacing-32 $spacing-20 0 $spacing-20;
      font-size: $font-size-24;
      line-height: 133%;
    }
  }
}

.mat-toolbar.mat-primary {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: $spacing-20;
  min-height: $header-devices-height;
  gap: 0.625rem;
  background: $background;

  @media only screen and (min-width: variables.$screen-1140) {
    min-height: $header-desktop-height;
  }

  img {
    padding: $spacing-10;
    gap: 0.625rem;
    height: $header-img-devices-height;
    max-width: calc(100vw - 5rem);

    @media only screen and (min-width: variables.$screen-1140) {
      height: $header-img-desktop-height;
    }
  }
}

.mat-mdc-progress-bar .mdc-linear-progress__buffer-bar {
  background-color: $surface;
}

footer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: $spacing-12;
  gap: 3.5rem;
  background: $surface;

  a {
    width: 3.5rem;
    height: $footer-img-height;
  }

  img {
    width: 3.5rem;
    height: $footer-img-height;
  }

  p {
    font-weight: $font-weight-500;
    font-size: $font-size-12;
    line-height: $line-height-16;
    text-align: center;
    letter-spacing: $letter-spacing-0_5;
  }
}

.m-top {
  @media only screen and (min-width: calc($screen-768)) {
    margin-top: $spacing-40;
  }
}

.gap-12 {
  display: flex;
  flex-direction: column;
  gap: $spacing-16;

  @media only screen and (max-height: calc($screen-768 - 1px)) and (orientation: landscape){
    gap: $spacing-12;
  }

  @media only screen and (max-width: calc($screen-768 - 1px)) and (orientation: portrait){
    gap: $spacing-12;
  }
}

.paragraph {
  &.text-left {
    @media only screen and (max-width: calc($screen-768 - 1px)) and (orientation: portrait){
      text-align: left;
    }
  }

  ul {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding-inline-start: 1.5rem;
    margin: 0;

    @media only screen and (max-width: calc($screen-768 - 1px)) and (orientation: portrait){
      gap: 0.625rem;
    }
  }
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

form.column-form {
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
  width: $width-420;

  @media only screen and (max-width: calc($screen-768 - 1px)) {
    width: $width-320;
    gap: 1rem;
  }
}

form.grid-form {
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
  height: $w_100;
  width: $width-405;
  transform: rotate(-90deg);

  @media only screen and (max-width: calc($screen-768 - 1px)) {
    gap: 1rem;
  }
}

form.grid-form-eq5d {
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
  height: $w_100;
  width: $width-520;
  transform: rotate(-90deg);

  @media only screen and (max-width: calc($screen-768 - 1px)) {
    width: $width-450;
    gap: 1rem;
  }
}

.full-width {
  width: $w_100;
}

fieldset {
  border: none;
  width: $w_100;
  padding: 0;
  margin: 0;
}

.answer-group {
  display: flex;
  flex-direction: column;
  width: $w_100;
  justify-content: center;
  align-items: center;
  gap: 1rem;

  @media only screen and (max-width: calc($screen-768 - 1px)) {
    gap: 0.75rem;
  }

  .mat-mdc-card {
    box-shadow: none;
  }

  .answer-card {
    border-radius: 6.25rem;
    width: inherit;
    user-select: none;

    &:focus-visible {
      outline: 3px solid $on-surface;
    }
  }

  .checked-answer-card {
    border-radius: 6.25rem;
    width: inherit;
    user-select: none;
  
    &:focus-visible {
      outline: 3px solid var(--theme-primary-500);
    }
  }

  .answer-button {
    width: $w_100;
    user-select: none;

    .mdc-radio {
      display: none;
    }

    &.mat-mdc-radio-checked {
      .mdc-form-field {
        background: var(--theme-primary-50);
        color: var(--theme-primary-700);
        border: 1px solid var(--theme-primary-500);
        font-weight: $font-weight-500;
        max-width: $w_100;
        width: $w_100;

        &:hover {
          box-shadow: $box-shadow;
          background: $surface;
          border: 1px solid var(--theme-primary-500);
        }

        &:focus-visible {
          border: 3px solid var(--theme-primary-500);
          background: var(--theme-primary-50);
        }

        &:active {
          font-weight: $font-weight-800;
          background: var(--theme-primary-50);
          border: 1px solid var(--theme-primary-500);
        }
      }
    }

    .mdc-form-field {
      display: flex;
      align-items: center;
      flex-direction: column;
      gap: 0.5rem;
      cursor: pointer;
      border: 1px solid $on-surface;
      border-radius: 6.25rem;
      width: $w_100;
      max-width: $w_100;
      font-size: $font-size-16;
      background-color: $background;
      color: $on-background;

      &:hover {
        background: $surface;
        border: 1px solid $on-surface;
        box-shadow: $box-shadow;
      }

      &:focus-visible {
        background: $background;
        border: 3px solid $on-surface;
      }

      &:active {
        background: $surface;
        border: 1px solid $on-surface;
        font-weight: $font-weight-500;
      }

      label {
        padding-left: 0;
        line-height: 150%;
        letter-spacing: $letter-spacing-0_5;
        margin: $spacing-12 $spacing-24;
        cursor: pointer;

        @media only screen and (max-width: calc($screen-768 - 1px)) {
          line-height: 143%;
          letter-spacing: $letter-spacing-0_25;
          font-size: $font-size-14;
          margin: $spacing-8 $spacing-12;
        }
      }
    }
  }
}

.answer-list {
  width: $w_100;

  .mdc-list {
    margin: 0;
    padding: 0;
    width: $w_100;
    user-select: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;

    @media only screen and (max-width: calc($screen-768 - 1px)) {
      gap: 0.75rem;
    }

    div {
      width: $w_100;

      .mdc-button {
        height: max-content;
        padding: $spacing-8 $spacing-48;
        width: $w_100;
        cursor: pointer;
        border: 1px solid $on-surface;
        border-radius: 6.25rem;
        background-color: $background;
        color: $on-background;
        min-height: 3rem;
        font-size: $font-size-16;

        @media only screen and (max-width: calc($screen-768 - 1px)) {
          padding: $spacing-8 $spacing-16;
          min-height: 2.25rem;
          font-size: $font-size-14;
        }

        &:hover {
          background: $surface;
          border: 1px solid $on-surface;
          box-shadow: $box-shadow;
        }

        &:focus-visible {
          background: $background;
          border: 3px solid $on-surface;
        }

        &:active {
          background: $surface;
          border: 1px solid $on-surface;
          font-weight: $font-weight-500;
        }
      }

      .checked {
        background: var(--theme-primary-50);
        color: var(--theme-primary-700);
        border: 1px solid var(--theme-primary-500);
        font-weight: $font-weight-500;

        label {
          color: var(--theme-primary-700);
        }

        &:hover {
          box-shadow: $box-shadow;
          background: $surface;
          border: 1px solid var(--theme-primary-500);
        }

        &:focus-visible {
          border: 3px solid var(--theme-primary-500);
          background: var(--theme-primary-50);
        }

        &:active {
          font-weight: $font-weight-800;
          background: var(--theme-primary-50);
          border: 1px solid var(--theme-primary-500);
        }
      }
    }
  }
}

legend {
  display: none;
}

.w-60 {
  width: 60%
}

.inline-wrapper {
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  column-gap: 3.5rem;
  margin-top: 1.75rem;
  margin-bottom: 1.5rem;

  @media only screen and (max-width: calc($screen-768 - 1px)) and (orientation: portrait) {
    flex-direction: column;
    row-gap: 1rem;
    margin-top: 1.5rem;
  }

  &.eq5d {
    @media only screen and (min-width: calc($screen-768)) {
      margin-left: -28.875rem;
      min-width: $width-572;
    }
  }
}

.mat-mdc-form-field-bottom-align::before {
  display: none !important;
}

.hint {
  display: flex;
  flex-direction: column;
  gap: $spacing-8;
  padding-top: $spacing-8;
  font-weight: $font-weight-500;
  line-height: $line-height-16;
  letter-spacing: $letter-spacing-0_4;
  text-align: left;

  .input-error-message {
    color: var(--theme-error-500);
    margin-left: $spacing-12;
  }
}

.mat-mdc-form-field-hint-wrapper {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.mat-mdc-form-field-hint-spacer {
  flex: 1 0 0 !important;
}

.disabled {
  cursor: not-allowed;
}

.comment {
  &:has(.mdc-text-field--disabled) {
    cursor: not-allowed;
  }

  & div.mat-mdc-text-field-wrapper {
    min-height: 6rem;
    padding: $spacing-12;

    mat-label {
      font-size: $font-size-14;
      color: $on-surface;
    }

    &.mdc-text-field--disabled {
      mat-label {
        cursor: not-allowed;
        color: $disabled;
      }
    }
  }
}

.add-email-box {
  & div.mat-mdc-text-field-wrapper {
    min-height: 2.75rem;
    padding: variables.$spacing-12;

    mat-label {
      font-size: variables.$font-size-14;
      color: variables.$on-surface;
    }
  }

  & input.email-input-font {
    font-family: variables.$font-primary;
    font-style: normal;
    font-weight: variables.$font-weight-400;
    font-size: variables.$font-size-14;
    line-height: variables.$line-height-20;

    /* identical to box height, or 143% */

    letter-spacing: 0.1px;

    /* Colors "On"/Surface */

    color: #666;
  }

  height: 44px;
  width: variables.$w_100;
  margin: 10px 0;
}

.text-box,
.number-box {
  & div.mat-mdc-text-field-wrapper {
    max-height: 2.75rem;
    padding: $spacing-12;

    mat-label {
      font-size: $font-size-14;
      color: $on-surface;
    }
  }

    & .mdc-floating-label.mat-mdc-floating-label {
      height: 1.875rem;
    }
}

.email-box {
  & div.mat-mdc-text-field-wrapper {
    min-height: 2.75rem;
    padding: $spacing-12;

    mat-label {
      font-size: $font-size-14;
      color: $on-surface;
    }
  }
}

.mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
  padding: 0;
  height: 100%;
}

.mat-mdc-form-field-error {
  font-weight: $font-weight-500;
  line-height: $line-height-16;
  letter-spacing: $letter-spacing-0_5;
}

.error {
  text-align: center;
  font-size: $font-size-12;
  margin-top: $spacing-12;
  line-height: 133%;
  letter-spacing: $letter-spacing-0_4;

  @media only screen and (max-width: calc($screen-768 - 1px)) {
    margin-top: $spacing-8;
  }
}

.subscript {
  :not(.mdc-text-field--disabled) + .mat-mdc-form-field-subscript-wrapper  {
    height: 2.5rem;

    @media only screen and (max-width: calc($screen-768 - 1px)) {
      height: 3.5rem;
    }
  }
}

.long_subscript {
  :not(.mdc-text-field--disabled) + .mat-mdc-form-field-subscript-wrapper  {
    height: 4rem;

    @media only screen and (max-width: calc($screen-768 - 1px)) {
        height: 5rem;
    }
  }
}

.subscript_number {
  :not(.mdc-text-field--disabled) + .mat-mdc-form-field-subscript-wrapper  {
    &:has(.input-error-message) {
      height: 1.5rem;
    }
  }
}

.add-email-box {
  & div.mat-mdc-text-field-wrapper {
    min-height: 2.75rem;
    padding: variables.$spacing-12;

    mat-label {
      font-size: variables.$font-size-14;
      color: variables.$on-surface;
    }
  }

  & input.email-input-font {
    font-family: variables.$font-primary;
    font-style: normal;
    font-weight: variables.$font-weight-400;
    font-size: variables.$font-size-14;
    line-height: variables.$line-height-20;
    letter-spacing: 0.1px;
    color: #666;
  }

  height: 44px;
  width: variables.$w_100;
  margin: 10px 0;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
}

.notices {
  /* Large devices (laptops/desktops, 768 and up) */
  @media only screen and (min-width: variables.$screen-768) {
    font-weight: variables.$font-weight-500;
    font-size: variables.$font-size-12;
    line-height: variables.$line-height-16;

    /* or 133% */
    letter-spacing: 0.5px;
    padding-bottom: 26px;
  }
}

.email-input-wrapper {
  width: 75%;
}

header.border-bottom {
  border-bottom: #e6e6e6 4px solid;
}

.centered-title {
  font-style: normal;
  font-weight: variables.$font-weight-400;
  text-align: center;

  /* Large devices (laptops/desktops) or Tablets */
  @media only screen and (min-width: variables.$screen-768) {
    font-size: variables.$font-size-28;
    line-height: 36px;

    /* or 129% */
  }

  @media only screen and (max-width: variables.$screen-768) {
    font-size: variables.$font-size-24;
    line-height: 32px;
  }
}

.mobile-landing-wrapper {
  padding: 0 20%;

  @media only screen and (max-width: variables.$screen-1140) {
    width: auto;
  }

  @media only screen and (max-width: variables.$screen-768) {
    padding: 0 10%;
  }
}

.not-found-text {
  margin: 3rem 3.8125rem;
  text-align: center;
  font-size: 1.75rem;
  line-height: 129%;

  @media only screen and (max-width: calc(48rem - 1px)) {
    margin: 2rem 1.25rem;
    font-size: 1.5rem;
    line-height: 133%;
  }
}

.error-message {
  text-align: center;
  color: red;
  font-size: 10px;
}

.m-12 {
  margin-left: $spacing-12;
  margin-right: $spacing-12;

  @media only screen and (max-width: calc($screen-768 - 1px)) and (orientation: portrait) {
    margin: 0;
  }
}

//PARAGRAPH STYLES
.small {
  margin-top: 1.5em;
  font-size: 0.57em;
  line-height: 150%;
  letter-spacing: $letter-spacing-0_5;

  &.left {
    text-align: left;
  }
}

.small-text {
  font-size: 0.57em;
  line-height: 150%;
  letter-spacing: $letter-spacing-0_5;
}

.question-small-text {
  margin-bottom: 1em;
  font-size: 0.75em;
  line-height: 150%;
  letter-spacing: 0.15px;
}

.question-big-centered-text {
  margin-bottom: 0.55em;
  font-size: 1.27em;
  line-height: 127%;
  letter-spacing: 0.5px;
  text-align: center;
}

.mt-40 {
  margin-top: $spacing-40;
}

.mt-24 {
  @media only screen and (min-width: calc($screen-768)) {
    margin-top: $spacing-24;
  }
}

.mt-8 {
  margin-top: $spacing-8;

  @media only screen and (max-width: calc($screen-768 - 1px)) and (orientation: portrait) {
    margin-top: 0;
  }
}
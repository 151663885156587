@use "sass:map";
@use "@angular/material" as mat;
@import "@angular/material/theming";
@include mat.core;
@import "styles/variables";

$custom-typography: mat.define-typography-config(
  $font-family: $font-primary,
);
$primary-palette: (
  50 : var(--theme-primary-50),
  100 : var(--theme-primary-100),
  200 : var(--theme-primary-200),
  300 : var(--theme-primary-300),
  400 : var(--theme-primary-400),
  500 : var(--theme-primary-500),
  600 : var(--theme-primary-600),
  700 : var(--theme-primary-700),
  800 : var(--theme-primary-800),
  900 : var(--theme-primary-900),
  a100 : var(--theme-primary-A100),
  a200 : var(--theme-primary-A200),
  a400 : var(--theme-primary-A400),
  a700 : var(--theme-primary-A700),
  contrast: (
    50: var(--theme-primary-contrast-50),
    100: var(--theme-primary-contrast-100),
    200: var(--theme-primary-contrast-200),
    300: var(--theme-primary-contrast-300),
    400: var(--theme-primary-contrast-400),
    500: var(--theme-primary-contrast-500),
    600: var(--theme-primary-contrast-600),
    700: var(--theme-primary-contrast-700),
    800: var(--theme-primary-contrast-800),
    900: var(--theme-primary-contrast-900),
    a100: var(--theme-primary-contrast-A100),
    a200: var(--theme-primary-contrast-A200),
    a400: var(--theme-primary-contrast-A400),
    a700: var(--theme-primary-contrast-A700),
  )
);
$secondary-palette: (
  50 : var(--theme-secondary-50),
  100 : var(--theme-secondary-100),
  200 : var(--theme-secondary-200),
  300 : var(--theme-secondary-300),
  400 : var(--theme-secondary-400),
  500 : var(--theme-secondary-500),
  600 : var(--theme-secondary-600),
  700 : var(--theme-secondary-700),
  800 : var(--theme-secondary-800),
  900 : var(--theme-secondary-900),
  a100 : var(--theme-secondary-A100),
  a200 : var(--theme-secondary-A200),
  a400 : var(--theme-secondary-A400),
  a700 : var(--theme-secondary-A700),
  contrast: (
    50: var(--theme-secondary-contrast-50),
    100: var(--theme-secondary-contrast-100),
    200: var(--theme-secondary-contrast-200),
    300: var(--theme-secondary-contrast-300),
    400: var(--theme-secondary-contrast-400),
    500: var(--theme-secondary-contrast-500),
    600: var(--theme-secondary-contrast-600),
    700: var(--theme-secondary-contrast-700),
    800: var(--theme-secondary-contrast-800),
    900: var(--theme-secondary-contrast-900),
    a100: var(--theme-secondary-contrast-A100),
    a200: var(--theme-secondary-contrast-A200),
    a400: var(--theme-secondary-contrast-A400),
    a700: var(--theme-secondary-contrast-A700),
  )
);
$error-palette: (
  50 : var(--theme-error-50),
  100 : var(--theme-error-100),
  200 : var(--theme-error-200),
  300 : var(--theme-error-300),
  400 : var(--theme-error-400),
  500 : var(--theme-error-500),
  600 : var(--theme-error-600),
  700 : var(--theme-error-700),
  800 : var(--theme-error-800),
  900 : var(--theme-error-900),
  a100 : var(--theme-error-A100),
  a200 : var(--theme-error-A200),
  a400 : var(--theme-error-A400),
  a700 : var(--theme-error-A700),
  contrast: (
    50: var(--theme-error-contrast-50),
    100: var(--theme-error-contrast-100),
    200: var(--theme-error-contrast-200),
    300: var(--theme-error-contrast-300),
    400: var(--theme-error-contrast-400),
    500: var(--theme-error-contrast-500),
    600: var(--theme-error-contrast-600),
    700: var(--theme-error-contrast-700),
    800: var(--theme-error-contrast-800),
    900: var(--theme-error-contrast-900),
    a100: var(--theme-error-contrast-A100),
    a200: var(--theme-error-contrast-A200),
    a400: var(--theme-error-contrast-A400),
    a700: var(--theme-error-contrast-A700),
  )
);
$warn_palette: mat-palette($error-palette);
$primary: mat-palette($primary-palette);
$secondary: mat-palette($secondary-palette);
$warn: mat-palette($warn_palette);
$theme: mat.define-light-theme(
  (color:
  (
    primary: $primary,
    accent: $secondary,
    warn: $warn
  ),
  typography: $custom-typography)
);

@include mat.all-component-themes($theme);

html,
body {
  width: 100%;
  height: 100%;
}

body {
  margin: 0;
  font-family: $font-primary;
  font-style: $font-style;
  font-size: 16px; // 1rem = 16px
  font-weight: $font-weight-400;
  color: $on-background;
  background: $back-background;
}

p {
  margin: 0;
}

.primary-color {
    color:var(--theme-primary-500);
}

@import "styles/layout";
@import "styles/button";
@import "styles/spinner";
@import "styles/slider";
@import "styles/scores";
@import url('https://fonts.googleapis.com/icon?family=Material+Icons|Material+Icons+Outlined');
@import "styles/video-player";
@import "styles/consent";

/* breakpoints */
$screen-1140: 71.25rem;
$screen-768: 48rem;
$screen-360: 22.5rem;

/* palette */
$back-background: #efefef;
$background: #fff;
$white: #fff;
$on-background: #333;
$primary: #0b8453;
$on-primary: #fff;
$tertiary: #defcf0;
$secondary: #106789;
$on-secondary: #fff;
$surface: #e6e6e6;
$sub-surface: #f2f2f2;
$on-surface: #666;
$error: #ca1008;
$on-error: #fff;
$error-container: #fed9d7;
$on-error-container: #490703;
$disabled: #a8a8a8;
$track-grey: #9f9f9f;
$green: #068913;

/**** TYPOGRAPHY ****/
$font-primary: "Roboto", sans-serif;
$font-style: "normal";

/* font size */
$font-size-11: 0.6875rem;
$font-size-12: 0.75rem;
$font-size-14: 0.875rem;
$font-size-15: 0.9375rem;
$font-size-16: 1rem;
$font-size-18: 1.125;
$font-size-20: 1.25rem;
$font-size-22: 1.375rem;
$font-size-24: 1.5rem;
$font-size-28: 1.75rem;
$font-size-32: 2rem;

/* font weight */
$font-weight-300: 300;
$font-weight-400: 400;
$font-weight-500: 500;
$font-weight-600: 600;
$font-weight-700: 700;
$font-weight-800: 800;
$font-weight-900: 900;

/* line height */
$line-height-16: 1rem;
$line-height-20: 1.25rem;
$line-height-24: 1.5rem;
$letter-spacing-0_1: 0.1px;
$letter-spacing-0_15: 0.15px;
$letter-spacing-0_25: 0.25px;
$letter-spacing-0_4: 0.4px;
$letter-spacing-0_5: 0.5px;

/** spacing **/
$spacing-4: 0.25rem;
$spacing-6: 0.375rem;
$spacing-8: 0.5rem;
$spacing-10: 0.625rem;
$spacing-11: 0.7rem;
$spacing-12: 0.75rem;
$spacing-14: 0.875rem;
$spacing-16: 1rem;
$spacing-20: 1.25rem;
$spacing-22: 1.375rem;
$spacing-24: 1.5rem;
$spacing-32: 2rem;
$spacing-36: 2.25rem;
$spacing-40: 2.5rem;
$spacing-48: 3rem;
$spacing-56: 3.5rem;
$spacing-61: 3.8125rem;
$spacing-110: 6.875rem;
$spacing-111_5: 6.969rem;
$spacing-112: 7rem;
$header-desktop-height: 8.875rem;
$header-devices-height: 6.5rem;
$header-img-desktop-height: 6.375rem;
$header-img-devices-height: 4rem;
$width-688: 43rem;
$width-572: 35.75rem;
$width-520: 32.5rem;
$width-455: 28.44rem;
$width-450: 28.125rem;
$width-420: 26.25rem;
$width-428: 26.75rem;
$width-405: 25.3125rem;
$width-320: 20rem;
$width-240: 15rem;
$width-130: 8.125rem;
$height-450: 28.125rem;
$box-shadow: 0 1px 2px rgb(0 0 0 / 30%), 0 1px 3px 1px rgb(0 0 0 / 15%);
$box-shadow-score-card: 0px 3px 6px rgba(0, 0, 0, 0.3), 0px 2px 8px 1px rgba(0, 0, 0, 0.15);
$box-shadow-patient-score: 0px 2px 4px rgba(0, 0, 0, 0.15), 3px 1px 4px rgba(0, 0, 0, 0.1);
$w_100: 100%;
$footer-img-height: 1.25rem;
$color-black: #000;
$color-black-45-percent: rgba($color-black, 0.45);
$scrollbar-thumb-border: 0.25rem solid transparent;

$step-1: 1;
$step-2: 2;
$step-5: 5;
$step-10: 10;
$step-20: 20;
$step-25: 25;
$step-50: 50;
$step-100: 100;

$step-map: (
  1: $step-1,
  2: $step-2,
  5: $step-5,
  10: $step-10,
  20: $step-20,
  25: $step-25,
  50: $step-50,
  100: $step-100,
);


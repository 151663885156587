@import "variables";

.spinner-container {
  display: flex;
  position: absolute;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  padding: 0;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba($white, 1);
  z-index: 9999;
  width: $w_100;
  max-width: calc($screen-768 + 1.25rem);

  /* Large devices (laptops/desktops, 1140 and up) */
  @media only screen and (min-width: $screen-1140) {
    max-width: calc($screen-1140 + 1.25rem);
  }
}

.spinner {
  content: "";
  width: 2.25rem;
  height: 2.25rem;
  margin: 0 auto;
  border: 5px solid $on-surface;
  border-radius: 50%;
  border-color: $on-surface;
  border-left-color: transparent;
  border-right-color: transparent;
  animation: motion 1.5s infinite linear;
}

@keyframes motion {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes motion {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes motion {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes motion {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes motion {
  100% {
    transform: rotate(360deg);
  }
}

@use "src/styles/variables";

mat-card.scrolling
{
   overflow-y: scroll;
   max-height: $height-450;
   border: 1px solid $on-surface;
   box-shadow: none;
   border-radius: 4px;
}

::-webkit-scrollbar {
    width: 0.9rem;
    background: $background;

    @media only screen and (max-width: calc($screen-768 - 1px)) and (orientation: portrait) {
        width: 0.8rem;
    }
}
  
::-webkit-scrollbar-thumb {
    border: $scrollbar-thumb-border;
    background: $color-black-45-percent;
    border-radius: 0.75rem;
    background-clip: padding-box;
}

.consent {
    display: flex;
    gap: 1.5rem;
    margin: $spacing-48 $spacing-40 0 $spacing-40;
    flex-direction: column;
    font-size: $font-size-28;
    line-height: 129%;
    text-align: center;

    @media only screen and (max-width: calc($screen-768 - 1px)) and (orientation: landscape) {
      font-size: $font-size-24;
      line-height: 133%;
    }

    @media only screen and (max-width: calc($screen-768 - 1px)) and (orientation: portrait) {
      width: $width-320;
      max-width: $width-320;
      margin: $spacing-24 $spacing-20 0 $spacing-20;
      font-size: $font-size-24;
      line-height: 133%;
      gap: 0.5rem;
    }

    &-form {
        display: flex;
        flex-direction: column;
        gap: 1.375rem;

        @media only screen and (max-width: calc($screen-768 - 1px)) and (orientation: portrait) {
            gap: 0.5rem;
        }

        &.small-width {
            width: $width-420;

            @media only screen and (max-width: calc($screen-768 - 1px)) and (orientation: portrait) {
                width: $width-320;
            }
        }    
    
        .research-question {            
            & div.mat-mdc-text-field-wrapper {
                margin-top: 0.5rem;
                min-height: 8.75rem;
                padding: $spacing-12;
            
                mat-label {
                    font-size: $font-size-14;
                    color: $on-surface;
                }
            }
        }

        &-radio-buttons {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 0.5rem;
        
            @media only screen and (max-width: calc($screen-768 - 1px)) and (orientation: portrait) {
                margin-top: 0.5rem;
                flex-direction: row;
                gap: 2.5rem;
            }
        }

        &-checkbox {
            text-align: left;
        }
    }    
}

.table {
    max-width: fit-content;
    padding: 0.5rem;
    display: grid;
    gap: 0.75rem;

    &-value {
        text-align: left;
        font-size: $font-size-16;
        line-height: 188%;
        vertical-align: top;

        @media only screen and (max-width: calc($screen-768 - 1px)) and (orientation: portrait) {
            font-size: $font-size-14;
            line-height: 143%;
            letter-spacing: $letter-spacing-0_25;
        }
    }

    &-label {
        min-width: $width-240;
        max-width: $width-240;
        text-align: left;
        font-size: $font-size-22;
        line-height: 127%;
        vertical-align: top;

        @media only screen and (max-width: calc($screen-768 - 1px)) and (orientation: portrait) {
            min-width: $width-130;
            max-width: $width-130;
            font-weight: $font-weight-500;
            font-size: $font-size-16;
            line-height: 150%;
            letter-spacing: $letter-spacing-0_15;
        }
    }
}

.mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:enabled~.mdc-checkbox__background .mdc-checkbox__checkmark {
    --mdc-checkbox-selected-checkmark-color: #fff;
}
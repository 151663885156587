@use "src/styles/variables";

form {
    .mdc-slider .mdc-slider__value-indicator-text {
        font-weight: $font-weight-500;
        font-size: $font-size-12;
        line-height: 133%;
        letter-spacing: $letter-spacing-0_5;
    }

    .horizontal {
        &.mat-mdc-slider {
            margin: 0;
            width: $w_100;
            min-width: $w_100;
        }

        .slider-wrap {
            display: flex;
            flex-direction: column;

            datalist {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                writing-mode: vertical-lr;
                width: calc($w_100 + $spacing-6);

                & label {
                    text-align: left;
                    transform: rotate(-90deg);
                    margin-top: -0.5rem;
                    padding: 0;
                    font-size: $font-size-12;
                    line-height: 133%;
                    letter-spacing: $letter-spacing-0_5;
                }
            }
        }

        .center {
            .mat-mdc-slider.mat-primary {
                --mdc-slider-with-tick-marks-active-container-color: #333;
            }

            .mat-mdc-slider .mdc-slider__tick-marks {
                display: flex;
                align-items: flex-start;
                margin-top: -2px;
            }
        }
    }

    .hideSticks {
        .mat-mdc-slider .mdc-slider__tick-mark--active,
        .mat-mdc-slider .mdc-slider__tick-mark--inactive {
            opacity: 0;
        }
    }

    .defaultSticks {
        .mat-mdc-slider .mdc-slider__tick-mark--active,
        .mat-mdc-slider .mdc-slider__tick-mark--inactive {
            width: 1px;
            height: 0.875rem;
            border-radius: 0;
            opacity: 0.38;
        }
    }

    .vertical-eq5d {
        min-height: $width-520;
        justify-content: space-around;

        @media only screen and (max-width: calc($screen-768 - 1px)) {
            min-height: $width-455;
        }

        .slider-wrap {
            display: flex;
            flex-direction: column;

            datalist {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                writing-mode: vertical-lr;
                width: calc(100% + 0.5rem);
                margin-left: -0.375rem;

                & label {
                    text-align: left;
                    margin-top: -0.3rem;
                    padding: 0;
                    font-size: $font-size-12;
                    line-height: 133%;
                    letter-spacing: $letter-spacing-0_5;
                }
            }

            .mdc-slider .mdc-slider__value-indicator-text {
                transform: rotate(90deg);
            }
        }

        .center {
            .mat-mdc-slider.mat-primary {
                --mdc-slider-with-tick-marks-active-container-color: #333;
            }

            .mat-mdc-slider .mdc-slider__tick-marks {
                display: flex;
                align-items: flex-start;
                margin-top: -2px;
            }
        }
    }

    .vertical {
        min-height: $width-405;
        justify-content: space-around;

        @media only screen and (max-width: calc($screen-768 - 1px)) and (orientation: portrait) {
            min-height: $width-405;
        }

        .slider-wrap {
            display: flex;
            flex-direction: column;

            datalist {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                writing-mode: vertical-lr;
                width: calc(100% + 0.5rem);
                margin-left: -0.375rem;

                & label {
                    text-align: left;
                    margin-top: -0.3rem;
                    padding: 0;
                    font-size: $font-size-12;
                    line-height: 133%;
                    letter-spacing: $letter-spacing-0_5;
                }
            }

            .mdc-slider .mdc-slider__value-indicator-text {
                transform: rotate(90deg);
            }
        }

        .center {
            .mat-mdc-slider.mat-primary {
                --mdc-slider-with-tick-marks-active-container-color: #333;
            }

            .mat-mdc-slider .mdc-slider__tick-marks {
                display: flex;
                align-items: flex-start;
                margin-top: -2px;
            }

        }
    }
}

//STICKS POSITION
.below {
    .mat-mdc-slider.mat-primary {
        --mdc-slider-with-tick-marks-active-container-color: #333;
    }

    .mat-mdc-slider .mdc-slider__tick-mark--active,
    .mat-mdc-slider .mdc-slider__tick-mark--inactive {
        width: 1px;
        height: 0.5rem;
        border-radius: 0;
        opacity: 0.38;
    }

    .mat-mdc-slider .mdc-slider__tick-marks {
        height: 1rem;
        display: flex;
        align-items: flex-end;
    }
}

.mat-mdc-slider.mat-primary {
    --mat-mdc-slider-hover-ripple-color: var(--theme-primary-50);
    --mat-mdc-slider-focus-ripple-color: var(--theme-primary-50);
    --mdc-slider-inactive-track-color: #9f9f9f;
    --mdc-slider-with-tick-marks-active-container-color: transparent;
    --mdc-slider-with-tick-marks-inactive-container-color: #333;
    margin: 0;
}

.horizontal-flex-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    column-gap: $spacing-112;
    width: calc($w_100 + $spacing-6);
    margin-top: $spacing-11;
    font-weight: $font-weight-500;
    font-size: $font-size-14;
    line-height: 143%;
    letter-spacing: $letter-spacing-0_1;

    span {
        &.left {
            text-align: left;
        }

        &.right {
            text-align: right;
        }
    }
}

.vertical-flex-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-weight: $font-weight-500;
    font-size: $font-size-14;
    line-height: 143%;
    letter-spacing: $letter-spacing-0_1;
    max-width: $spacing-110;
    gap: $spacing-22;
}

.error_range {
    text-align: center;
    font-size: $font-size-14;
    line-height: 143%;
    letter-spacing: $letter-spacing-0_1;

    @media only screen and (max-height: calc($screen-768 - 1px)) and (orientation: landscape){
        min-width: $width-420;
    }

    &.eq5d {
        @media only screen and (min-width: calc($screen-768)) {
          margin-left: -28.875rem;
          width: $width-572;
          display: flex;
          justify-content: center;
        }
    }
}

.range_wrapper {
    display: flex;
    flex-direction: column;
    gap: $spacing-24;

    @media only screen and (max-width: calc($screen-768 - 1px)) {
        gap: $spacing-12;
    }
}

.slider-wrapper {
    display: flex;
    flex-direction: row;
    gap: $spacing-24;
    align-items: center;
    justify-content: center;
    width: $width-420;

    @media only screen and (max-width: calc($screen-768 - 1px)) and (orientation: portrait) {
        gap: $spacing-12;
        width: $width-320;
        justify-content: center;
    }

    &.eq5d {
        @media only screen and (min-width: calc($screen-768)) {
            max-width: 6.875rem;
            justify-content: end;
        }
    }
}

.flex {
    display: flex;

    .label-current-value {
        font-size: $font-size-22;
        line-height: 127%;
        text-align: left;

        @media only screen and (max-width: calc($screen-768 - 1px)) {
            font-size: $font-size-16;
            line-height: 175%;

        }

        &.card {
            @media only screen and (min-width: calc($screen-768 - 0.0625rem)) {
                display: grid;
                grid-template-columns: 14.6875rem 5rem;
                grid-column-gap: 0.5rem;
                align-items: center;
            }

            @media only screen and (max-height: calc($screen-768 - 1px)) and (orientation: landscape){
                display: grid;
                grid-template-columns: 11rem 5.3125rem;
                grid-column-gap: 0.5rem;
                align-items: center;
            }

            @media only screen and (max-width: calc($screen-768 - 1px)) and (orientation: portrait) {
                background-color: rgba($on-surface, 12%);
                padding: $spacing-12 $spacing-24;
                min-width: 6.6875rem;
                max-width: 6.6875rem;
                box-shadow: 0px 0.125rem 0.25rem rgba($on-surface, 0.15), 0.1875rem 0.0625rem 0.25rem rgba($on-surface, 0.1);
                border-radius: 0.25rem;
            }

            .bold {
                font-weight: $font-weight-700;
                text-align: center;

                @media only screen and (max-height: calc($screen-768 - 1px)) and (orientation: landscape){
                    min-height: 1.75rem;
                    background-color: rgba($on-surface, 12%);
                    padding: $spacing-12 $spacing-24;
                    box-shadow: 0px 0.125rem 0.25rem rgba($on-surface, 0.15), 0.1875rem 0.0625rem 0.25rem rgba($on-surface, 0.1);
                    border-radius: 0.25rem;
                }

                @media only screen and (min-width: calc($screen-768 - 0.0625rem)) {
                    min-height: 1.75rem;
                    background-color: rgba($on-surface, 12%);
                    padding: $spacing-12 $spacing-24;
                    box-shadow: 0px 0.125rem 0.25rem rgba($on-surface, 0.15), 0.1875rem 0.0625rem 0.25rem rgba($on-surface, 0.1);
                    border-radius: 0.25rem;
                }
            }
        }
    }
}

// sticks settings
@each $key, $val in $step-map {
    .largeTicksStep-#{$key} {
        .mat-mdc-slider .mdc-slider__tick-mark--active:nth-child(#{$val}n+1),
        .mat-mdc-slider .mdc-slider__tick-mark--inactive:nth-child(#{$val}n+1) {
            width: 1px;
            border-radius: 0;
            height: 0.875rem !important;
            opacity: 0.6;
        }
    }

    .mediumTicksStep-#{$key} {
        .mat-mdc-slider .mdc-slider__tick-mark--active:nth-child(#{$val}n+1),
        .mat-mdc-slider .mdc-slider__tick-mark--inactive:nth-child(#{$val}n+1) {
            width: 1px;
            border-radius: 0;
            height: 0.6875rem;
            opacity: 0.6;
        }
    }

    .smallTicksStep-#{$key} {
        .mat-mdc-slider .mdc-slider__tick-mark--active:nth-child(#{$val}n+1),
        .mat-mdc-slider .mdc-slider__tick-mark--inactive:nth-child(#{$val}n+1) {
            width: 1px;
            border-radius: 0;
            height: 0.5625rem;
            opacity: 0.38;
        }
    }

    .slider-not-clicked {
        ~ mat-slider-visual-thumb {
            visibility: hidden;
            -webkit-appearance: none;
        }
    }
}
